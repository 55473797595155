// import axiosInstance from '../utils/axiosInstance';

// export async function loginApi(username, password) {
//   const response = await axiosInstance.post('/auth/login', { username, password });
//   return response.data; // { token: '...', role: '...' }
// }
// Mock登入資料示範
// username: user, password: 123  => Normal角色
// username: admin, password: 123 => Admin角色
// username: super, password: 123 => Super角色
// 其他則失敗
function safeBase64(obj) {
  const str = JSON.stringify(obj);
  return btoa(unescape(encodeURIComponent(str)));
}

function createMockToken(payload) {
  // ASCII only fields
  // name: JohnUser, AdminMaster, SuperBoss
  // avatar: use simple ASCII url
  // authType: 1,2,3
  const headerObj = { alg:"HS256", typ:"JWT" };
  const header = safeBase64(headerObj);
  const body = safeBase64(payload);
  const signature = "MOCKSIG";
  return `${header}.${body}.${signature}`;
}

export async function loginApi(username, password) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (username === 'user' && password === '123') {
        const token = createMockToken({ sub:'user001', name:'JohnUser', avatar:'https://example.com/avatar.png', authType:1 });
        resolve(token);
      } else if (username === 'admin' && password === '123') {
        const token = createMockToken({ sub:'admin001', name:'AdminMaster', avatar:'https://example.com/admin.png', authType:2 });
        resolve(token);
      } else if (username === 'super' && password === '123') {
        const token = createMockToken({ sub:'super001', name:'SuperBoss', avatar:'https://example.com/super.png', authType:3 });
        resolve(token);
      } else {
        reject(new Error('Invalid username or password'));
      }
    }, 500);
  });
}