// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getUserFromToken, setToken, removeToken } from '../utils/jwt';

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(() => getUserFromToken());

  useEffect(() => {
    setUser(getUserFromToken());
  }, []);

  const login = (token) => {
    setToken(token);
    setUser(getUserFromToken());
  };

  const logout = () => {
    removeToken();
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
