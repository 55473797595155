// src/pages/SuperPage.jsx
import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

// 樣式
const PageWrapper = styled.div`
  padding:20px;

  @media(max-width:768px) {
    padding:10px;
  }
`;

// SuperPage 組件
export default function SuperPage() {
  return (
    <PageWrapper>
      <Title level={2}>超級管理員頁面</Title>
      <Paragraph>只有超級管理員可以看到這個頁面。</Paragraph>
    </PageWrapper>
  );
}
