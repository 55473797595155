// src/pages/NotFoundPage.jsx
import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

const { Title } = Typography;

// 樣式
const PageWrapper = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  height:100vh;
  background:#f8f8f8;

  @media(max-width:768px) {
    padding:10px;
  }
`;

// NotFoundPage 組件
export default function NotFoundPage() {
  return (
    <PageWrapper>
      <Title level={1} style={{color:'#ff4d4f'}}>404</Title>
      <Title level={3}>頁面未找到</Title>
      <p>抱歉，您訪問的頁面不存在。</p>
    </PageWrapper>
  );
}
