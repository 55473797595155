// src/components/TopBar.jsx
import React, { useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../context/AuthContext';
import { Button, Avatar } from 'antd';

const TopBarWrapper = styled.div`
  width:100%;
  height:60px;
  background:#fff;
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding:0 20px;
  box-sizing:border-box;
  border-bottom:1px solid #ddd;

  @media(max-width:768px) {
    flex-direction:column;
    height:auto;
    padding:10px;
    align-items:flex-start;
  }
`;

const UserInfo = styled.div`
  display:flex;
  align-items:center;
  gap:10px;

  @media(max-width:768px) {
    flex-direction:column;
    align-items:flex-start;
  }
`;

const Name = styled.span`
  font-weight:bold;
  color:#333;
  font-size:16px;
  margin-right:10px;
`;

const Role = styled.span`
  font-size:14px;
  color:#999;
  margin-right:10px;
`;

const Position = styled.span`
  font-size:14px;
  color:#333;
  margin-right:10px;
`;

export function TopBar() {
  const { user, logout } = useContext(AuthContext);

  return (
    <TopBarWrapper>
      {user ? (
        <UserInfo>
          <Avatar 
            src={user.avatar || 'https://via.placeholder.com/80'} 
            alt="avatar" 
            size={40}
          />
          <div>
            <Name>{user.name}</Name>
            <Position>{user.position}</Position>
            <Role>角色: {user.role}</Role>
          </div>
        </UserInfo>
      ) : <div />}

      {user && <Button onClick={logout}>登出</Button>}
    </TopBarWrapper>
  );
}
