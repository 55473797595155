import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import BusinessManPage from './pages/BusinessManPage';
import AdminPage from './pages/AdminPage';
import SuperPage from './pages/SuperPage';
import { ProtectedRoute } from './components/ProtectedRoute';

export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />

          {/* 已登入才能看首頁及 Normal 頁面 */}
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/business" element={<BusinessManPage />} />
          </Route>

          {/* Admin(2) & Super(3) 才能看 AdminPage */}
          <Route element={<ProtectedRoute roles={['Admin','Super']}/>}>
            <Route path="/admin" element={<AdminPage />} />
          </Route>

          {/* Only Super(3) */}
          <Route element={<ProtectedRoute roles={['Super']}/>}>
            <Route path="/super" element={<SuperPage />} />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}
