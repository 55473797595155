import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

export function ProtectedRoute({ roles }) {
  const { user } = useContext(AuthContext);
  if (!user) {
    // 未登入
    return <Navigate to="/login" />;
  }

  if (roles && !roles.includes(user.role)) {
    // 無對應角色權限
    return <Navigate to="/" />;
  }

  return <Outlet />;
}
