// src/pages/LoginPage.jsx
import React, { useState, useContext } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import styled, { keyframes } from 'styled-components';
import { loginApi } from '../api/auth';
import { MessageModal } from '../components/MessageModal';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

// 動畫
const rotateAnim = keyframes`
  0% {transform:rotateX(0deg) rotateY(0deg);}
  100% {transform:rotateX(360deg) rotateY(360deg);}
`;

const Container = styled.div`
  position:relative;
  width:100vw;
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
  background:#000;
  overflow:hidden;
`;

const BackgroundLayer = styled.div`
  position:absolute;
  top:0; left:0; right:0; bottom:0;
  z-index:-1;
  overflow:hidden;
`;

const BuildingContainer = styled.div`
  position:absolute;
  perspective:800px;
  ${props => `
    top:${props.top};
    left:${props.left};
    width:${props.size};
    height:${props.size};
  `}
`;

const BuildingLines = styled.div`
  position:absolute;
  top:50%; left:50%;
  transform: translate(-50%, -50%);
  width:100%; height:100%;
  border:1px solid #4ca1af;
  transform-style: preserve-3d;
  animation:${rotateAnim} 20s linear infinite;
  opacity:0.5;
`;

const LoginBox = styled.div`
  position:relative;
  z-index:1;
  background: rgba(40,40,40,0.4);
  padding:40px;
  border-radius:16px;
  max-width:300px;
  width:100%;
  box-sizing:border-box;
  box-shadow: 0 12px 24px rgba(0,0,0,0.2);
  backdrop-filter: blur(5px);
  text-align:center;

  @media(max-width:768px) {
    padding:30px;
    max-width:250px;
  }
`;

const StyledForm = styled(Form)`
  .ant-form-item-label > label {
    color: #fff;
  }

  .ant-input, .ant-input-password {
    background:rgba(255,255,255,0.1);
    border:none;
    color:#fff;
    border-radius:4px;
  }

  .ant-input:hover, .ant-input:focus,
  .ant-input-password:hover .ant-input, .ant-input-password:focus .ant-input {
    border:none;
    outline:none;
    box-shadow:0 0 3px rgba(255,255,255,0.5);
  }

  .ant-btn-primary {
    background: #4ca1af;
    border-color: #4ca1af;
    border-radius:4px;
    &:hover {
      background: #2c3e50;
      border-color: #2c3e50;
    }
  }
`;

export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({ visible: false, title:'', message:'', isError:false });
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    const { username, password } = values;
    try {
      const token = await loginApi(username, password);
      login(token);
      navigate('/');
      setModalInfo({
        visible:true,
        title:'成功',
        message:'登入成功！',
        isError:false
      });
    } catch(err) {
      setModalInfo({
        visible:true,
        title:'錯誤',
        message: err.message || '登入失敗',
        isError:true
      });
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setModalInfo({ ...modalInfo, visible:false });
  };

  return (
    <Container>
      <BackgroundLayer>
        {/* 散佈多組BuildingContainer於畫面不同位置 */}
        <BuildingContainer top="20%" left="10%" size="200px">
          <BuildingLines/>
        </BuildingContainer>

        <BuildingContainer top="50%" left="80%" size="300px">
          <BuildingLines/>
        </BuildingContainer>

        <BuildingContainer top="70%" left="30%" size="150px">
          <BuildingLines/>
        </BuildingContainer>

        <BuildingContainer top="30%" left="50%" size="250px">
          <BuildingLines/>
        </BuildingContainer>
      </BackgroundLayer>

      <LoginBox>
        <Title level={2} style={{color:'#fff', marginBottom:'20px'}}>登入</Title>
        <StyledForm name="loginForm" onFinish={onFinish} layout="vertical">
          <Form.Item 
            name="username" 
            label="帳號" 
            rules={[{ required:true, message:'請輸入您的帳號！' }]}
          >
            <Input placeholder="帳號"/>
          </Form.Item>

          <Form.Item 
            name="password" 
            label="密碼" 
            rules={[{ required:true, message:'請輸入您的密碼！' }]}
          >
            <Input.Password placeholder="密碼"/>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              登入
            </Button>
          </Form.Item>
        </StyledForm>
      </LoginBox>

      <MessageModal
        visible={modalInfo.visible}
        title={modalInfo.title}
        message={modalInfo.message}
        isError={modalInfo.isError}
        onClose={handleModalClose}
      />
    </Container>
  );
}
