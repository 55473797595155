// src/api/commodity.js
import axiosInstance from '../utils/axiosInstance';
import { handleApiResponse } from './common';

export async function getPagedObjectInformation(pageNumber=1, pageSize=10) {
  // const response = await axiosInstance.get(`/commodity?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  // return handleApiResponse(response);
  return new Promise((resolve) => {
    setTimeout(() => {
      const total = 53; // 模擬總共有53筆資料
      const start = (pageNumber-1)*pageSize;
      const end = start + pageSize;
      const items = Array.from({length:pageSize}, (_,i)=>({
        id: start+i+1,
        name:`物件 ${start+i+1}`,
        price: Math.floor(Math.random()*1000000)+100000
      })).filter(item=>item.id<=total);

      const result = {
        PageNumber: pageNumber,
        PageSize: pageSize,
        TotalRecords: total,
        TotalPages: Math.ceil(total/pageSize),
        Items: items,
        HasPreviousPage: pageNumber>1,
        HasNextPage: pageNumber<Math.ceil(total/pageSize)
      };
      resolve(result);
    },500);
  });
}
