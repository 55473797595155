// src/components/Sidebar.jsx
import React, { useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import {
  HomeOutlined,
  ApartmentOutlined,
  UserOutlined,
  FormOutlined,
  SettingOutlined,
  StarOutlined
} from '@ant-design/icons';

const SidebarWrapper = styled.div`
  width:20%;
  background:#fff;
  height:calc(100vh - 60px); /* 扣除TopBar的高度 */
  padding:10px;
  box-sizing:border-box;
  border-right:1px solid #ddd;
  overflow:auto;

  @media(max-width:768px) {
    width:100%;
    height:auto;
    flex-direction:row;
    overflow-x:scroll;
  }
`;

export function Sidebar() {
  const { user } = useContext(AuthContext);

  if(!user) return null;

  let menuItems = [
    {
      key: 'home',
      icon: <HomeOutlined />,
      label: <Link to="/">首頁</Link>,
    },
    {
      key: 'property',
      icon: <ApartmentOutlined />,
      label: '物件管理',
      children: [
        {
          key:'property-list',
          icon:<UserOutlined />,
          label:<Link to="/businessman">物件列表</Link>
        },
        {
          key:'property-feedback',
          icon:<FormOutlined />,
          label:'回應表單(待實作)'
        }
      ]
    }
  ];

  if (user.role === 'Admin' || user.role === 'Super') {
    menuItems.push({
      key: 'admin',
      icon: <SettingOutlined />,
      label: '用戶管理',
      children: [
        {
          key:'user-list',
          label:<Link to="/user-list">用戶列表(範例子功能)</Link>
        },
        {
          key:'role-config',
          label:'角色配置(待實作)'
        }
      ]
    });
  }

  if (user.role === 'Super') {
    menuItems.push({
      key:'super',
      icon:<StarOutlined/>,
      label:'系統設定',
      children:[
        { key:'param-setting', label:'參數設定(待實作)' },
        { key:'advanced-tool', label:'高級工具(待實作)' }
      ]
    });
  }

  return (
    <SidebarWrapper>
      <Menu mode="inline" items={menuItems} style={{height:'100%'}} />
    </SidebarWrapper>
  );
}
