// src/pages/BusinessManPage.jsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography, Table, Pagination, Spin } from 'antd';
import { getPagedObjectInformation } from '../api/objectInformation';
import { MessageModal } from '../components/MessageModal';

const { Title } = Typography;

const PageWrapper = styled.div`
  padding:20px;

  @media(max-width:768px) {
    padding:10px;
  }
`;

export default function BusinessManPage() {
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({visible:false, title:'', message:'', isError:false});

  const columns = [
    { title: 'ID', dataIndex: 'id', key:'id', width:80 },
    { title: '名稱', dataIndex: 'name', key:'name' },
    { title: '價格', dataIndex: 'price', key:'price' },
  ];

  const fetchData = async (p=pageNumber, s=pageSize) => {
    setLoading(true);
    try {
      const result = await getPagedObjectInformation(p, s);
      setData(result.Items || []);
      setTotalRecords(result.TotalRecords);
      setPageNumber(result.PageNumber);
      setPageSize(result.PageSize);
    } catch(err) {
      setModalInfo({
        visible:true,
        title:'錯誤',
        message: err.message || '取得列表失敗',
        isError:true
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageChange = (page, size) => {
    fetchData(page, size);
  };

  const handleModalClose = () => {
    setModalInfo({...modalInfo, visible:false});
  };

  return (
    <PageWrapper>
      <Title level={2}>物件列表</Title>
      <p>此頁面示範從後端取得 PagedResult 並顯示假資料列表。</p>
      {loading ? <Spin/> : (
        <>
          <Table 
            columns={columns} 
            dataSource={data} 
            rowKey="id"
            pagination={false}
            style={{marginBottom:'20px'}}
          />
          <Pagination 
            current={pageNumber} 
            pageSize={pageSize} 
            total={totalRecords} 
            onChange={handlePageChange}
            showSizeChanger 
            onShowSizeChange={handlePageChange}
          />
        </>
      )}

      <MessageModal
        visible={modalInfo.visible}
        title={modalInfo.title}
        message={modalInfo.message}
        isError={modalInfo.isError}
        onClose={handleModalClose}
      />
    </PageWrapper>
  );
}
