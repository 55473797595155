import axios from 'axios';
import { getToken } from './jwt';

const instance = axios.create({
  baseURL: 'https://your-api-base-url.com', // 替換為實際後端 API
  headers: {
    'Content-Type': 'application/json',
  }
});

instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if(token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
